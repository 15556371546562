const config = {
  api: 'https://gurungaji.hanief.org',
  directus: {
    folder: {
      avatar: 'df63677d-aec1-4bc2-b259-0ef827c350ad',
    },
  },
}

export default config
