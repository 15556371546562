import Compress from 'compress.js'
import moment from 'moment'

const compress = new Compress()

export const compressImg = (e: any) => {
  const files = [...e.target.files]
  const data = compress
    .compress(files, {
      quality: 0.7, // the quality of the image, max is 1,
      maxWidth: 1080, // the max width of the output image, defaults to 1920px
      maxHeight: 1080, // the max height of the output image, defaults to 1920px
    })
    .then((results) => {
      // Example mimes:
      // image/png, image/jpeg, image/jpg, image/gif, image/bmp, image/tiff, image/x-icon,  image/svg+xml, image/webp, image/xxx, image/png, image/jpeg, image/webp
      // If mime is not provided, it will default to image/jpeg
      const img1 = results[0]
      const base64str = img1.data
      const imgExt = img1.ext
      const file = Compress.convertBase64ToFile(base64str, imgExt)
      return file
      // -> Blob {size: 457012, type: "image/png"}
    })
  return data
}

export const convertRangeTime = (start: string, end: string) => {
  // start time and end time
  var startTime = moment(start, 'HH:mm:ss')
  var endTime = moment(end, 'HH:mm:ss')

  // calculate total duration
  var duration = moment.duration(endTime?.diff(startTime))

  // duration in hours
  var hours = parseInt(duration?.asHours() as any)

  // duration in minutes
  var minutes = parseInt(duration?.asMinutes() as any) % 60

  if (!isNaN(hours) && !isNaN(minutes)) {
    return hours + ' jam ' + minutes + ' menit'
  }
  return null
}

export function toDataURL(url: string, callback: any) {
  var xhr = new XMLHttpRequest()
  xhr.onload = function () {
    var reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export function getToday(format = "yyyy-mm-dd", locale = "Asia/Jakarta") {

  const now = new Date().toLocaleString("id-ID", {timeZone: locale}).split(', ');
  const year = now[0].split('/')[2];
  const month = ('0' + now[0].split('/')[1]).slice(-2);
  const date = ('0' + now[0].split('/')[0]).slice(-2);
  const time = now[1].replace('.', ':');

  switch (format) {
    case "yyyy-mm-dd": return `${year}-${month}-${date}`;
    break;
    case "dd-mm-yyyy hh:ss": return `${date}-${month}-${year} ${time}`;
    break;
    case "dd-mm-yyyy": return `${date}-${month}-${year}`;
    default: return `${year}-${month}-${date}`;
    break;
  }


}