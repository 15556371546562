
import AuthRoute from 'layouts/AuthRoute'
import PrivateRoute from 'layouts/PrivateRoute'
import ThemedSuspense from 'layouts/ThemedSuspense'
import EditProfile from 'pages/EditProfile'
import ForgotPassword from 'pages/ForgotPassword'
import ResetPassword from 'pages/ResetPassword'
import History from 'pages/HistoryTilawah'
import Home from 'pages/Home'
import Login from 'pages/Login'
import { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import CatatTilawah from 'pages/CatatTilawah'
import HistoryTilawah from 'pages/HistoryTilawah'
import Info from 'pages/Info'

const App = () => {

  return (
    <BrowserRouter>
      <Suspense fallback={<ThemedSuspense />}>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route index element={<Home />} />
            <Route path="catat-tilawah" element={<CatatTilawah />} />
            <Route path="history-tilawah" element={<HistoryTilawah />} />
            <Route path="edit-profil" element={<EditProfile />} />
            <Route path="info" element={<Info />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
