import { Box, BoxProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

const Layout = ({
  children,
  noPadding = false,
  ...props
}: BoxProps & { noPadding?: boolean; children: ReactNode }) => {
  return (
    <Box {...props} display="flex" justifyContent="center" alignItems="center">
      <Box w={{ base: '85%', md: '45%', lg: '40%' }} py={noPadding ? 0 : 4}>
        {children}
      </Box>
    </Box>
  )
}

export default Layout
