import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Img,
  Input,
  Spacer,
  Stack,
  StackDivider,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { Field, FieldInputProps, Form, Formik } from 'formik'
import Layout from 'layouts/Layout'
import { Link, useNavigate } from 'react-router-dom'
import { directus } from 'utils/constant'
import * as Yup from 'yup'

const Login = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const navigate = useNavigate()

  return (
    <Layout minH="100vh" noPadding>
      <VStack
        spacing={12}
        align='stretch'
      >
        <Center>
          <Image
            boxSize='120px'
            objectFit='contain'
            src='/img/al-quran.png'
            alt='Al Quran'
          />
        </Center>
        
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required(),
            password: Yup.string().min(6).required(),
          })}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async ({ email, password }, { setSubmitting }) => {
            setSubmitting(true)
            await directus.auth
              .login({ email, password })
              .then(() => queryClient.invalidateQueries())
              .then(() => navigate('/'))
              .catch((err) =>
                toast({
                  position: 'top-right',
                  title: 'Gagal masuk',
                  description:
                    err?.response?.errors?.[0]?.message || 'Something went wrong',
                  status: 'error',
                  isClosable: true,
                })
              )
            setSubmitting(false)
          }}
        >
          {({ errors, status, touched, isSubmitting }) => (
            <Form>
              <Stack spacing={4}>
                <Field name="email">
                  {({ field }: { field: FieldInputProps<string> }) => (
                    <FormControl
                      isInvalid={errors.email && touched.email ? true : false}
                    >
                      <FormLabel>Email</FormLabel>
                      <Input {...field} />
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field }: { field: FieldInputProps<string> }) => (
                    <FormControl
                      isInvalid={
                        errors.password && touched.password ? true : false
                      }
                    >
                      <FormLabel>Password</FormLabel>
                      <Input {...field} type="password" />
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Stack>
              <Button mt={8} type="submit" isLoading={isSubmitting} width="full">
                Masuk
              </Button>
              <HStack
                mt={4}
                justifyContent="end"
                fontWeight="medium"
                color="telegram.500"
                fontSize="sm"
              >
                <Link to="/forgot-password">Lupa Password?</Link>
              </HStack>
            </Form>
          )}
        </Formik>
      </VStack>
    </Layout>
  )
}

export default Login
