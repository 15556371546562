import { QueryClient, QueryKey, useQuery } from '@tanstack/react-query'
import { directus } from 'utils/constant'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
    },
  },
})

const localStoragePersistor = createSyncStoragePersister({
  storage: window.localStorage,
})

const persistQueryKeys: QueryKey[] = [['get-surah']]

persistQueryClient({
  queryClient,
  persister: localStoragePersistor,
  maxAge: Infinity,
  hydrateOptions: {},
  dehydrateOptions: {
    dehydrateMutations: false,
    dehydrateQueries: true,
    shouldDehydrateQuery: ({ queryKey }) => {
      return persistQueryKeys.includes(queryKey)
    },
  },
})

export const useToken = () => {
  return useQuery(
    ['get-token'],
    () => directus.auth.token,
    {
      cacheTime: 900000, //cache for 15 mins (make sure to set directus refresh token expiration accordingly).
      staleTime: 660000, //gotta be a lil bit lower than cacheTime just to be on the safe side.
    }
  )
}

export const useUser = () => {
  return useQuery(['get-user'], () =>
    directus.users.me.read({
      fields: '*,teacher_info.*,teacher_info.class,student_info.*,student_info.class,tilawah_last_verse.*',
    }),
    {
      cacheTime: 43200000, //cache for 12 hours
      staleTime: 43200000,
    }
  )
}

export const useSurah = () => {
  return useQuery(['get-surah'], () =>
    directus.items('surah').readByQuery({
      fields: 'id,nama,num_of_verse,surah,verse.id,verse.meta',
      limit: -1,
      sort: 'id' as any,
      deep: {
        verses: {
          _limit: -1,
          _sort: 'id' as any,
        }
      }
    }),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      cacheTime: Infinity, //cache for 12 hours
      staleTime: Infinity,
    }
  )
} 

