import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DashboardType } from 'utils/types/dashboard.type'

const initialState: DashboardType = {
  bottomTabShow: true,
}

const dashboardReducer = createSlice({
  name: 'tilawah',
  initialState,
  reducers: {
    bottomTabShow: (state, action: PayloadAction<DashboardType['bottomTabShow']>) => {
      state.bottomTabShow = action.payload
    },
    lastVerse: (state, action: PayloadAction<DashboardType['lastVerse']>) => {
      state.lastVerse = action.payload
    },
    lastSurah: (state, action: PayloadAction<DashboardType['lastSurah']>) => {
      state.lastSurah = action.payload
    },
    lastPage: (state, action: PayloadAction<DashboardType['lastPage']>) => {
      state.lastPage = action.payload
    },
  },
})

export const {
  bottomTabShow: setDashboardBottomTabShow,
  lastVerse: setDashboardLastVerse,
  lastSurah: setDashboardLastSurah,
  lastPage: setDashboardLastPage,
} = dashboardReducer.actions

export default dashboardReducer.reducer
