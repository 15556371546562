import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  Divider,
  HStack,
  Icon,
  Link as LinkChakra,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Switch,
  Text,
  useColorMode,
  useDisclosure,
  Badge,
  Card,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  CardBody,
  CardHeader,
  Heading,
  Spacer,
  useToast,
} from '@chakra-ui/react'
import { ChevronRightIcon, ArrowTopRightOnSquareIcon, PencilSquareIcon } from '@heroicons/react/24/solid'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Avatar from 'components/Avatar'
import { useUser } from 'hooks/useDirectus'
import Container from 'layouts/Container'
import ThemedSuspense from 'layouts/ThemedSuspense'
import moment from 'moment'
import { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { directus } from 'utils/constant'
import { date } from 'yup'

const Wrapper = ({ title, value }: { title: string; value?: any }) => {
  return (
    <Box>
      <Text fontSize="sm">{title}:</Text>
      <Text fontWeight="semibold">{value || '-'}</Text>
    </Box>
  )
}

const Home = () => {
  const queryClient = useQueryClient()
  const { toggleColorMode, colorMode, setColorMode } = useColorMode()
  const { data: user, isLoading: userLoading, refetch } = useUser()
  const navigate = useNavigate()
  const [ isReset, setIsReset ] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const buttonRef = useRef(null)
  const toast = useToast();

  const { data: tilawah, isLoading: tilawahLoading } = useQuery(['tilawah', user?.id], () =>
    directus.items('tilawah').readByQuery({
      fields: 'date_created,total_pages,total_surah,total_juz',
      filter: {
        user_created: user?.id
      },
      limit: 30,
      sort: ['-date_created'] as any,
    })
    .then((res) => {
      let tilawahData = res.data?.map((item) => {
        item.date_created = moment(item.date_created).format('DD-MM-YY');
        return item;
      });
      let tilawah = tilawahData?.reduce((acc, obj) => {
        let existObj = acc.find((item: any) => item.date_created === obj.date_created);
        if (existObj) {
          existObj.total_pages = (parseInt(existObj.total_pages) + parseInt(obj.total_pages));
          existObj.total_surah = (parseInt(existObj.total_surah) + parseInt(obj.total_surah));
          existObj.total_juz = (parseInt(existObj.total_juz) + parseInt(obj.total_juz));
          return acc;
        }
        acc.push(obj);
        
        return acc;
      },[]);
      if (tilawah.length < 2) {
        return false
      }
      return tilawah;
    }),
    {
      enabled: !!user?.id,
      cacheTime: 43200000, //cache for 12 hours
      staleTime: 43200000,
    }
  )

  useEffect(() => {

    user?.theme === 'dark' ? setColorMode('dark') : setColorMode('light');
    //data?.employee_profile?.[0]?.level ? onOpen() : onClose();

  }, [ user?.theme, setColorMode, isReset ] )

  const { mutate: mutateTheme } = useMutation((value) =>
    directus.users.me.update({ theme: value as unknown as string })
  )

  const {
    mutate,
    isLoading: mutateLoading,
  } = useMutation(() => directus.auth.password.request(user?.email || '', 'https://ngaji.hanief.org/reset-password'), {
    onSuccess: () => setIsReset(true),
  })

  const EmployeeLevel = () => {
    switch(user?.employee_profile?.[0]?.level) {
      case 'store_crew':   return <Badge variant={'solid'} colorScheme={'yellow'}>Kru</Badge>;
      case 'store_leader': return <Badge variant={'solid'} colorScheme={'blue'}>Kapten</Badge>;
      case 'store_spv':    return <Badge variant={'solid'} colorScheme={'pink'}>Supervisor</Badge>;
      case 'hq_staff':     return <Badge variant={'solid'} colorScheme={'whatsapp'}>HQ Staff</Badge>;
      default:             return;
    }
  }

  if (userLoading || tilawahLoading) return <ThemedSuspense />

  return (
    <Container>
      <Stack spacing={8}>
        <Card>
          <CardBody>
          <Modal isOpen={isOpen} onClose={onClose} isCentered blockScrollOnMount initialFocusRef={buttonRef} >
            <ModalOverlay />
            <ModalContent w={'90vw'}>
              <ModalHeader></ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                
              </ModalBody>
              <ModalFooter>
                <Button variant='link' mr={3} onClick={onClose}>Nanti saja</Button>
                <Link to='/info' >
                  <Button rightIcon={<Icon as={ArrowTopRightOnSquareIcon} fontSize={'1rem'} />} ref={buttonRef}>
                    
                  </Button>
                </Link>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <HStack mb={4}>
            <Avatar
              name={user?.first_name || user?.last_name || ''}
              src={user?.avatar || ''}
              callback={() => refetch()}
            />
            <Box pl={2}>
              <Text textTransform='uppercase' fontWeight='bold'>
                {user?.first_name} {user?.last_name}
              </Text>
              <Link to="/edit-profil">
                <Button
                  variant='outline'
                  size="xs"
                  colorScheme="gray"
                  borderRadius="full"
                  leftIcon={<PencilSquareIcon width={12} />}
                  textTransform="uppercase"
                  fontSize='10px'
                >
                  Edit
                </Button>
              </Link>
            </Box>
          </HStack>

          </CardBody>
        </Card>


        {!!tilawah &&
        <Card>
          <CardBody>
            <Text color='GrayText' fontStyle='italic' fontSize='12px' >Statistik Tilawah Terkini</Text>
            <Spacer height={1} />
            <StatGroup>
              <Stat>
                <StatLabel fontSize='md'>Halaman</StatLabel>
                <StatNumber>{tilawah[0].total_pages}</StatNumber>
                <StatHelpText>
                  <StatArrow type={tilawah[0].total_pages > tilawah[1].total_pages ? 'increase' : 'decrease'} />
                    {tilawah[0].total_pages - tilawah[1].total_pages}
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel fontSize='md'>Surah</StatLabel>
                <StatNumber>{tilawah[0].total_surah}</StatNumber>
                <StatHelpText>
                  <StatArrow type={tilawah[0].total_surah > tilawah[1].total_surah ? 'increase' : 'decrease'} />
                    {tilawah[0].total_surah - tilawah[1].total_surah}
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel fontSize='md'>Juz</StatLabel>
                <StatNumber>{tilawah[0].total_juz}</StatNumber>
                <StatHelpText>
                  <StatArrow type={tilawah[0].total_juz > tilawah[1].total_juz ? 'increase' : 'decrease'} />
                    {tilawah[0].total_juz - tilawah[1].total_juz}
                </StatHelpText>
              </Stat>
            </StatGroup>
          </CardBody>
        </Card>
        }

        <Card>
          <CardBody>
          
          <Box
            id='dark-mode-toggle'
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text>Dark mode</Text>
            <Switch
              isChecked={colorMode === 'dark'}
              onChange={({ target }) => {
                toggleColorMode()
                mutateTheme(target.checked ? 'dark' : 'light' as any)
              }}
              size="lg"
            />
          </Box>
   
          <Box
            id='reset-password-button'
            display="flex"
            justifyContent="space-between"
            alignItems='baseline'
          >
            <Text>Lupa password?</Text>
            <Button
              size='sm'
              mt={6}
              isLoading={mutateLoading}
              onClick={() => mutate()}
              isDisabled={isReset}
              variant='solid'
              colorScheme='red'
            >
              Reset Password
            </Button>
          </Box>
          
          </CardBody>
          </Card>
          <Spacer height={16} />
          <Button
            width='full'
            colorScheme='gray'
            variant='solid'
            borderRadius='full'
            onClick={async () => {
              await directus.auth.logout()
              .then(() => navigate('/login'))
              .then(() => queryClient.invalidateQueries())
            }}
            textTransform='uppercase'
            letterSpacing={2}
            size='md'
          >
            Keluar
          </Button>

          <Box>
          <Stack spacing={4}>
            
            
            {isReset && !toast.isActive('password-reset-alert') && (
              toast({
                id: 'password-reset-alert',
                position: 'top',
                title: 'Reset password berhasil.',
                description: 'Periksa email anda untuk langkah selanjutnya.',
                status: 'info',
                isClosable: true,
                duration: null,
                onCloseComplete() {
                    setIsReset(false)
                },
              })
            )}
            
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export default Home
