import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
} from '@chakra-ui/react'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import { Field, FieldInputProps, Form, Formik } from 'formik'
import Layout from 'layouts/Layout'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { directus } from 'utils/constant'

const ForgotPassword = () => {
  const [message, setMessage] = useState<string | null>(null)
  const [isDisabled, setIsDisabled] = useState(false)

  return (
    <Layout minH="100vh" noPadding>
      <Link to="/">
        <Button
          mb={6}
          aria-label=""
          size="sm"
          variant="link"
          leftIcon={<ArrowLeftIcon width={20} />}
        >
          Kembali
        </Button>
      </Link>
      <Formik
        initialValues={{
          email: '',
          url: 'https://ngaji.hanief.org/reset-password'
        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async ({ email, url }, { setSubmitting, setStatus }) => {
          setIsDisabled(false)
          setSubmitting(true)
          setStatus(true)
          await directus.auth.password
            .request(email, url)
            .then(() => {
              setStatus(true)
              setMessage('Silahkan check email anda')
            })
            .catch((err) => {
              setStatus(false)
              setMessage('Gagal')
            })
          setIsDisabled(true)
          setSubmitting(false)
        }}
      >
        {({ errors, status, touched, isSubmitting, setStatus }) => (
          <Form>
            <Stack spacing={4}>
              <Field name="email">
                {({ field }: { field: FieldInputProps<string> }) => (
                  <FormControl
                    isInvalid={errors.email && touched.email ? true : false}
                  >
                    <FormLabel>Email</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>{errors.email as any}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              {message && (
                <Alert
                  status={status ? 'success' : 'error'}
                  variant="left-accent"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <HStack>
                    <AlertIcon />
                    <AlertDescription>{message}</AlertDescription>
                  </HStack>
                  <CloseButton onClick={() => setMessage(null)} />
                </Alert>
              )}
            </Stack>
            <Button
              disabled={isDisabled}
              mt={8}
              type="submit"
              isLoading={isSubmitting}
              width="full"
            >
              Kirim
            </Button>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default ForgotPassword
