import { Box } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { createColumnHelper } from '@tanstack/react-table'
import HistoryChart from 'components/HistoryChart'
import HistoryTable from 'components/HistoryTable'
import { useUser } from 'hooks/useDirectus'
import BottomTabNavigator from 'layouts/BottomTabNavigator'
import ThemedSuspense from 'layouts/ThemedSuspense'
import moment from 'moment'
import { directus } from 'utils/constant'

const HistoryTilawah = () => {
  const { data: user } = useUser()
  const { data, isLoading } = useQuery(['tilawah', user?.id], () =>
    directus.items('tilawah').readByQuery({
      fields: 'date_created,total_pages,total_surah,total_juz',
      filter: {
        user_created: user?.id
      },
      limit: 30,
      sort: ['-date_created'] as any,
    })
    .then((res) => {
      let tilawahData = res.data?.map((item) => {
        item.date_created = moment(item.date_created).format('DD-MM-YY');
        return item;
      });
      let tilawah = tilawahData?.reduce((acc, obj) => {
        let existObj = acc.find((item: any) => item.date_created === obj.date_created);
        if (existObj) {
          existObj.total_pages = (parseInt(existObj.total_pages) + parseInt(obj.total_pages));
          existObj.total_surah = (parseInt(existObj.total_surah) + parseInt(obj.total_surah));
          existObj.total_juz = (parseInt(existObj.total_juz) + parseInt(obj.total_juz));
          return acc;
        }
        acc.push(obj);
        
        return acc;
      },[]);
      if (tilawah.length < 2) {
        return false
      }
      return tilawah;
    }),
    {
      enabled: !!user?.id,
      cacheTime: 43200000, //cache for 12 hours
      staleTime: 43200000,
    }
  )

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('date_created', {
      header: 'Tanggal',
    }),
    columnHelper.accessor('total_pages', {
      header: 'Total Halaman',
    }),
    columnHelper.accessor('total_surah', {
      header: 'Total Surah',
    }),
    columnHelper.accessor('total_juz', {
      header: 'Total Juz',
    }),
  ]
  if (isLoading) return <ThemedSuspense />

  return (
    <>
      <Box p={4}>
        <HistoryChart data={data} />
        <HistoryTable data={data} columns={columns} />
      </Box>
      <BottomTabNavigator />
    </>
  )
}

export default HistoryTilawah;
