import { Box, Image } from '@chakra-ui/react'

const ThemedSuspense = () => {
  return (
    <Box
      display="flex"
      minH="80vh"
      justifyContent="center"
      alignItems="center"
    >
      <Image width={100} src='./img/al-quran-base.png' zIndex={90} position='absolute' />
      <Image 
        width={98}
        src='./img/al-quran-spinner.png'
        zIndex={99}
        position='absolute'
        style={{animation: 'spin 7s linear infinite'}}
      />
    </Box>
  )
}

export default ThemedSuspense
