import { left } from '@popperjs/core';
import moment from 'moment';
import { memo, PureComponent } from 'react';
import { Legend, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

function CustomizedTick(props: any) {
    const { x, y, stroke, payload } = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text fontSize='11px' x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                {moment(payload.value).format('DD-MM-YY')}
            </text>
        </g>
    );
  }

function HistoryChart({ data = [] }: { data: any }) {
    
    return (
        <LineChart width={360} height={240} data={data} margin={{ top: 5, right: 20, bottom: 36, left: 0 }}>
            <Line name="Halaman" dataKey="total_pages" stroke="red" />
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
            <XAxis 
                reversed
                dataKey='date_created'
                fontSize='10px'
                />
            <YAxis fontSize='10px' interval={0} />
            <Tooltip />
            <Legend />
        </LineChart>
    );
}

export default HistoryChart