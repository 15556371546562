import Container from "layouts/Container"
import ThemedSuspense from "layouts/ThemedSuspense"

const Info = () => {
  

return <Container><ThemedSuspense /></Container>;

}

export default Info
