import {
  extendTheme,
  ThemeConfig,
  withDefaultColorScheme,
} from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
}

const theme = extendTheme(
  { 
    config,
  },
  withDefaultColorScheme({ colorScheme: 'twitter' }),
)

export default theme
