import {
  Avatar as CAvatar,
  AvatarBadge,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { UserIcon, CameraIcon, PhotoIcon } from '@heroicons/react/24/solid'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useState } from 'react'
import config from 'utils/config'
import { directus } from 'utils/constant'
import { compressImg, toDataURL } from 'utils/helper'

const { api } = config
const { avatar } = config.directus.folder

const Avatar = ({
  name,
  src,
  callback,
}: {
  callback: any
  name: string
  src: string
}) => {
  const toast = useToast()
  const [image, setImage] = useState<string>('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [imageName, setImageName] = useState('')

  useQuery(
    ['get-assets', src],
    () =>
      axios
        .get(`${api}/assets/${src}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        })
        .then(() =>
          toDataURL(`${api}/assets/${src}`, function (dataUrl: string) {
            setImage(dataUrl)
          })
        ),
    {
      enabled: !!src,
      cacheTime: 43200000, //cache for 12 hours
      staleTime: 43200000,
      
    }
  )

  function gallery() {
    document?.getElementById('gallery')?.click()
  }
  function camera() {
    document?.getElementById('camera')?.click()
  }

  const { mutate, isLoading } = useMutation(
    (value) => {
      const form = new FormData()
      form.append('folder', avatar)
      form.append('file', value as any, imageName)
      return directus.files
        .createOne(form)
        .then(
          async (res) => await directus.users.me.update({ avatar: res?.id })
        )
    },
    {
      onSuccess: () => {
        callback()
        toast({
          position: 'top-right',
          title: 'Berhasil update',
          status: 'success',
          isClosable: true,
        })
      },
      onError: () => {
        toast({
          position: 'top-right',
          title: 'Gagal update',
          status: 'error',
          isClosable: true,
        })
      },
    }
  )

  return (
    <Box
      cursor="pointer"
      onClick={onOpen}
      border="1px"
      borderRadius="full"
      borderColor="chakra-border-color"
    >
      <CAvatar
        size="lg"
        // name={name}
        bg={image === '' ? 'gray.400' : 'white'}
        src={image}
        icon={<UserIcon width="70%" color="white" />}
      >
        <AvatarBadge>
          <IconButton
            borderRadius="full"
            aria-label=""
            size="xs"
            icon={<CameraIcon width={15} />}
            isLoading={isLoading}
          />
          <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader borderBottomWidth="1px">Foto profil</DrawerHeader>
              <DrawerBody>
                <HStack spacing={5}>
                  <VStack>
                    <IconButton
                      borderRadius="full"
                      aria-label=""
                      variant="outline"
                      icon={<CameraIcon width={15} />}
                      onClick={camera}
                    />
                    <Text fontSize="sm">Kamera</Text>
                  </VStack>
                  <VStack>
                    <IconButton
                      borderRadius="full"
                      aria-label=""
                      variant="outline"
                      icon={<PhotoIcon width={15} />}
                      onClick={gallery}
                    />
                    <Text fontSize="sm">Galeri</Text>
                  </VStack>
                </HStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
          <input
            type="file"
            accept="image/*"
            capture
            hidden
            id="camera"
            onChange={async (e) => {
              setImageName(e?.target?.files?.[0]?.name || 'image')
              onClose()
              await compressImg(e).then((value: any) => mutate(value))
            }}
          />
          <input
            type="file"
            accept="image/*"
            hidden
            id="gallery"
            onChange={async (e) => {
              setImageName(e?.target?.files?.[0]?.name || 'image')
              onClose()
              await compressImg(e).then((value: any) => mutate(value))
            }}
          />
        </AvatarBadge>
      </CAvatar>
    </Box>
  )
}

export default Avatar
