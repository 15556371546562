import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import dashboardReducer from './reducers/dashboard/dashboard.reducer'

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
