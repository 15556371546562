import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
  useToast,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  VStack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import {
  ArrowLeftIcon,
  ArrowUpTrayIcon,
  CameraIcon,
  PhotoIcon,
} from '@heroicons/react/24/solid'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import WrapForm from 'components/WrapForm'
import { Field, FieldInputProps, Form, Formik } from 'formik'
import { useUser } from 'hooks/useDirectus'
import Container from 'layouts/Container'
import ThemedSuspense from 'layouts/ThemedSuspense'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import config from 'utils/config'
import { directus } from 'utils/constant'
import { compressImg } from 'utils/helper'
import * as Yup from 'yup'

const { avatar } = config.directus.folder

const EditProfile = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { data, isLoading } = useUser()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [imageName, setImageName] = useState('')

  function gallery() {
    document?.getElementById('gallery')?.click()
  }
  function camera() {
    document?.getElementById('camera')?.click()
  }

  const { mutate, isLoading: uploadLoad } = useMutation(async (value) => {
      const form = new FormData()
      form.append('folder', avatar)
      form.append('file', value as any, imageName)
      const res = await directus.files
        .createOne(form)
      return await directus.users.me.update({ id_file: res?.id })
    },
    {
      onSuccess: async(res) => {
        queryClient.invalidateQueries({ queryKey: ['get-user'] })
        queryClient.invalidateQueries({ queryKey: ['get-assets', res?.id] })
        toast({
          position: 'top',
          title: 'Berhasil update',
          status: 'success',
          isClosable: true,
        })
      },
      onError: () => {
        toast({
          position: 'top',
          title: 'Gagal update',
          status: 'error',
          isClosable: true,
        })
      },
    }
  )

  if (isLoading) return <ThemedSuspense />

  return (
    <Container>
      <Link to="/">
        <Button
          mb={6}
          aria-label=""
          size="sm"
          variant="link"
          leftIcon={<ArrowLeftIcon width={20} />}
        >
          Kembali
        </Button>
      </Link>
      <Formik
        initialValues={{
          first_name: data?.first_name || '',
          last_name: data?.last_name || '',
          address: data?.address || '',
          phone: data?.phone || '',
          sex: data?.sex || '',
          email: data?.email || '',
          dob: data?.dob || '',
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          first_name: Yup.string().required(),
          last_name: Yup.string(),
          address: Yup.string(),
          phone: Yup.string(),
          sex: Yup.string(),
          dob: Yup.string(),
          email: Yup.string().email(),
        })}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (
          { first_name, last_name, address, phone, sex, email, dob },
          { setSubmitting }
        ) => {
          setSubmitting(true)
          await directus.users.me
            .update({
              first_name,
              last_name,
              address,
              phone,
              sex,
              email,
              dob,
            })
            .then(() =>
              queryClient.invalidateQueries({ queryKey: ['get-user'] })
            )
            .then(() =>
              toast({
                position: 'top',
                title: 'Berhasil update data',
                status: 'success',
                isClosable: true,
              })
            )
            .catch((err) =>
              toast({
                position: 'top',
                title: 'Gagal update data',
                description:
                  err?.response?.errors?.[0]?.message || 'Something went wrong',
                status: 'error',
                isClosable: true,
              })
            )
          setSubmitting(false)
        }}
      >
        {({
          errors,
          status,
          touched,
          isSubmitting,
          setFieldValue,
          getFieldProps,
        }) => (
          <Form>
            <Stack spacing={4}>
              <Field name="first_name">
                {({ field }: { field: FieldInputProps<string> }) => (
                  <FormControl
                    isInvalid={
                      errors.first_name && touched.first_name ? true : false
                    }
                  >
                    <FormLabel>First Name</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>
                      {errors.first_name as any}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="last_name">
                {({ field }: { field: FieldInputProps<string> }) => (
                  <FormControl
                    isInvalid={
                      errors.last_name && touched.last_name ? true : false
                    }
                  >
                    <FormLabel>Last Name</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>
                      {errors.last_name as any}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="email">
                {({ field }: { field: FieldInputProps<string> }) => (
                  <FormControl
                    isInvalid={errors.email && touched.email ? true : false}
                  >
                    <FormLabel>Email</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>{errors.email as any}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="address">
                {({ field }: { field: FieldInputProps<string> }) => (
                  <FormControl
                    isInvalid={errors.address && touched.address ? true : false}
                  >
                    <FormLabel>Alamat</FormLabel>
                    <Textarea {...field} />
                    <FormErrorMessage>{errors.address as any}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="phone">
                {({ field }: { field: FieldInputProps<number> }) => (
                  <FormControl
                    isInvalid={errors.phone && touched.phone ? true : false}
                  >
                    <FormLabel>Telp</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>{errors.phone as any}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <WrapForm title="Jenis Kelamin">
                <RadioGroup
                  onChange={(val) => setFieldValue('sex', val)}
                  value={getFieldProps('sex').value}
                >
                  <Stack direction="row" spacing={4}>
                    <Radio value="f">Perempuan</Radio>
                    <Radio value="m">Laki-laki</Radio>
                  </Stack>
                </RadioGroup>
              </WrapForm>
              <Field name="dob">
                {({ field }: { field: FieldInputProps<string> }) => (
                  <FormControl
                    isInvalid={errors.dob && touched.dob ? true : false}
                  >
                    <FormLabel>Tanggal Lahir</FormLabel>
                    <Input type="date" {...field} />
                    <FormErrorMessage>{errors.dob as any}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>
            <Button size='md' mt={8} type="submit" isLoading={isSubmitting} width="full" textTransform='uppercase'>
              Simpan
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default EditProfile
