import Container from 'layouts/Container';
import { useSurah, useUser } from 'hooks/useDirectus';
import { directus } from 'utils/constant';
import { Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, VStack, useToast, Card, CardHeader, CardBody, Heading, Alert, AlertIcon, AlertTitle, NumberInput, NumberInputFieldProps, useNumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Box } from '@chakra-ui/react';
import { Select, IndicatorsContainerProps, components } from 'chakra-react-select';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

const CatatTilawah = () => { 

  const queryClient = useQueryClient();
  const {
    control, 
    getValues,
    handleSubmit, 
    register,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  
  const stepValue = (field: string, increase: boolean, limit: number) => {
    let value = getValues(field);
    if (typeof value !== 'number') value = 0; 
    if (increase) {
      if (value < limit) value = value + 1;
    } else {
      if (value > limit) value =  value - 1;
    }
    setValue(field, value, { shouldValidate: true })
  }

  const toast = useToast();

  const watchSurahStart = watch('surah_start', []);
  const watchVerseStart = watch('verse_start', 0);
  const watchSurahEnd   = watch('surah_end', []);
  const watchVerseEnd   = watch('verse_end', 0);

  const { data: surah } = useSurah();
  const { data: user }  = useUser();

  const onSubmit = (data: any) => {

    const surahStart = data.surah_start;
    const surahEnd = data.surah_end;
    const verseStart = surahStart.verse[(data.verse_start - 1)];
    const verseEnd = surahEnd.verse[(data.verse_end - 1)];

    const post = {
      surah_start: surahStart.id,
      surah_end: surahEnd.id,
      total_surah: (surahEnd.id - surahStart.id + 1),
      verse_start: verseStart.id,
      verse_end: verseEnd.id,
      total_verse: (verseEnd.id - verseStart.id + 1),
      page_start: verseStart.page,
      page_end: verseEnd.page,
      total_page: (verseEnd.page - verseStart.page),
      total_juz: (verseEnd.juz - verseStart.juz),
    };

    directus.items('tilawah')
    .createOne(post)
    .then(() => {
      toast({
        position: 'top',
        title: 'Berhasil mencatat tilawah.',
        status: 'success',
        variant: 'solid'
      })
      queryClient.invalidateQueries({ queryKey: ['tilawah', user?.id] })
    })
    .then(() => reset({}))
    .catch((err) =>
      toast({
        position: 'top',
        title: 'Gagal mencatat tilawah.',
        description: err?.response?.errors?.[0]?.message || 'Allahu alam',
        status: 'error',
        isClosable: true,
      })
    )
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)} >
        <VStack spacing={8} align='stretch'>
          <Card variant='outline'>
            <CardBody>
              <VStack spacing={4} align='stretch'>
              <Heading letterSpacing='3' textTransform='uppercase' fontWeight='bold' size='sm'>Awal Tilawah</Heading>
              
                <Controller
                  control={control}
                  name="surah_start"
                  defaultValue=''
                  rules={{ required: "Pilih surah" }}
                  render={({
                    field,
                    fieldState: { invalid, error }
                  }) => (
                    <FormControl id="surah-start" isInvalid={invalid}>
                      <Select 
                        {...field}
                        size='md'
                        placeholder="Pilih surah awal tilawah"
                        options={surah?.data || []}
                        getOptionLabel={(option) => `${option.id}. ${option.nama} (${option.surah})`}
                        getOptionValue={(option) => option}
                      />
                      <FormErrorMessage>{error && error.message}</FormErrorMessage>
                    </FormControl>
                  )}
                />
                
                { !!watchSurahStart &&
                  watchSurahStart['id'] > 0 &&
                  watchSurahStart['id'] <= 114 &&
                  <FormControl id="verse-start" isInvalid={!!errors.verse_start}>
                    <HStack spacing={2}>
                      <Button size='md' colorScheme='gray' onClick={() => stepValue('verse_start', false, 1)}>-</Button>
                      <Input
                          size='md'
                          type="number"
                          textAlign='center'
                          placeholder="Nomor ayat"
                          {...register('verse_start', {
                            required: 'Nomor ayat awal harus diisi',
                            min: { value: 1, message: 'Nomor ayat harus sama dengan 1 atau lebih' },
                            max: { value: (watchSurahStart?.num_of_verse ? watchSurahStart.num_of_verse as number : 300), message: 'Nomor ayat tidak bisa lebih dari jumlah ayat pada surah' },
                          })}
                        />
                        <Button size='md' colorScheme='gray' onClick={() => stepValue('verse_start', true, watchSurahStart.num_of_verse as number)}>+</Button>
                    </HStack>
                    <FormErrorMessage>{(typeof errors.verse_start?.message == 'string') && errors.verse_start?.message}</FormErrorMessage>
                  </FormControl>
                }
              </VStack>
            </CardBody>
          </Card>
          { !!watchSurahStart &&
            watchSurahStart['id'] > 0 &&
            watchSurahStart['id'] <= 114 &&
            !!watchVerseStart &&
            watchVerseStart > 0 &&
            watchVerseStart <= watchSurahStart.num_of_verse &&

            <Card variant='outline'>
              <CardBody>
                <VStack spacing={4} align='stretch'>
                <Heading letterSpacing='3' textTransform='uppercase' fontWeight='bold' size='sm'>Akhir Tilawah</Heading>
                
                  <Controller
                    control={control}
                    name="surah_end"
                    rules={{ required: "Pilih surah" }}
                    render={({
                      field,
                      fieldState: { invalid, error }
                    }) => (
                      <FormControl id="surah-end" isInvalid={invalid}>
                        <Select 
                          {...field}
                          size='md'
                          placeholder="Pilih surah akhir tilawah"
                          options={surah?.data || []}
                          getOptionLabel={(option) => `${option.id}. ${option.nama} (${option.surah})`}
                          getOptionValue={(option) => option}
                        />
                        <FormErrorMessage>{error && error.message}</FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  
                  { !!watchSurahEnd &&
                    watchSurahEnd['id'] > 0 &&
                    watchSurahEnd['id'] <= 114 &&
                    <FormControl id="verse-end" isInvalid={!!errors.verse_end}>
                      <HStack spacing={2}>
                        <Button size='md' colorScheme='gray' onClick={() => stepValue('verse_end', false, 1)}>-</Button>
                        <Input
                            size='md'
                            type="number"
                            textAlign='center'
                            placeholder="Nomor ayat"
                            {...register('verse_end', {
                              required: 'Nomor ayat akhir harus diisi',
                              min: { value: 1, message: 'Nomor ayat harus sama dengan 1 atau lebih' },
                              max: { value: (watchSurahEnd?.num_of_verse ? watchSurahEnd.num_of_verse as number : 300), message: 'Nomor ayat tidak bisa lebih dari jumlah ayat pada surah' },
                            })}
                          />
                          <Button size='md' colorScheme='gray' onClick={() => stepValue('verse_end', true, watchSurahEnd.num_of_verse as number)}>+</Button>
                      </HStack>
                      <FormErrorMessage>{(typeof errors.verse_end?.message == 'string') && errors.verse_end?.message}</FormErrorMessage>
                    </FormControl>
                  }
                </VStack>
              </CardBody>
            </Card>
          }
          { !!watchSurahStart && !!watchVerseStart &&
            watchSurahStart['id'] > 0 && watchSurahStart['id'] <= 114 &&
            watchVerseStart > 0 && watchVerseStart <= watchSurahStart.num_of_verse &&
            !!watchSurahEnd && !!watchVerseEnd &&
            watchSurahEnd['id'] > 0 && watchSurahEnd['id'] <= 114 &&
            watchVerseEnd > 0 && watchVerseEnd <= watchSurahEnd.num_of_verse &&
          
            <Button size='md' isLoading={isSubmitting} type='submit' w='full' letterSpacing={2} textTransform='uppercase' fontWeight='bold'>
              Simpan
            </Button>
          }
        </VStack>
      </form>
    </Container>
  );
};

export default CatatTilawah;