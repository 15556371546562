import {
    Alert,
    AlertDescription,
    AlertIcon,
    Button,
    CloseButton,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Text
  } from '@chakra-ui/react'
import { ArrowLeftIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { useQueryClient } from '@tanstack/react-query'
import { Field, FieldInputProps, Form, Formik } from 'formik'
import Layout from 'layouts/Layout'
import { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { directus } from 'utils/constant'
  
const ResetPassword = () => {
  const [message, setMessage] = useState<string | null>(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string;
  const queryClient = useQueryClient()
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  
  return (
    <Layout minH="100vh" noPadding>
      <Link to="/">
        <Button
          mb={6}
          aria-label=""
          size="sm"
          variant="link"
          leftIcon={<ArrowLeftIcon width={20} />}
        >
          Kembali
        </Button>
      </Link>
      <Formik
        initialValues={{
          token: token,
          password: ''
        }}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async ({ token, password }, { setSubmitting, setStatus }) => {
          setIsDisabled(false)
          setSubmitting(true)
          setStatus(true)
          await directus.auth.password
            .reset(token, password)
            .then(() => {
              setStatus(true)
              queryClient.invalidateQueries()
              setMessage('Password baru telah disimpan')
            })
            .catch((err) => {
              setStatus(false)
              setMessage('Gagal')
            })
          setIsDisabled(true)
          setSubmitting(false)
        }}
      >
        {({ errors, status, touched, isSubmitting, setStatus }) => (
          <Form>
            <Stack spacing={4}>
              <Field name="password">
                {({ field }: { field: FieldInputProps<string> }) => (
                  <FormControl isInvalid={errors.password && touched.password ? true : false}>
                    <FormLabel>Password Baru</FormLabel>
                    <InputGroup>
                      <Input {...field} type={show ? 'text' : 'password'} size='lg' pr='3rem'/>
                      <InputRightElement h='3rem' w='3rem'>
                        <Button colorScheme='gray' variant='link' onClick={handleClick}>
                          {show ? <Icon as={EyeSlashIcon} /> : <Icon as={EyeIcon} />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.password as any}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              {message && (
                <Alert
                  status={status ? 'success' : 'error'}
                  variant="left-accent"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <HStack>
                    <AlertIcon />
                    <AlertDescription>{message}</AlertDescription>
                  </HStack>
                  <CloseButton onClick={() => setMessage(null)} />
                </Alert>
              )}
            </Stack>
            <Button
              disabled={isDisabled}
              mt={6}
              size={"lg"}
              type="submit"
              isLoading={isSubmitting}
              width="full"
            >
              <Text>Ganti Password</Text>
            </Button>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}
  
export default ResetPassword
  