import { Box, Text } from '@chakra-ui/react'
import {
  UserIcon,
  InformationCircleIcon,
  CalendarIcon,
  BookOpenIcon
} from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import { useUser } from 'hooks/useDirectus'
import moment from 'moment'
import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { dateFormat, directus } from 'utils/constant'

const BottomTabNavigator = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { data: user } = useUser()
  
  const navigation = useMemo(
    () => [
      {
        path: '/',
        title: 'Profil',
        icon: UserIcon,
      },
      {
        path: '/catat-tilawah',
        title: 'Ngaji',
        icon: BookOpenIcon,
      },
      {
        path: '/history-tilawah',
        title: 'Riwayat',
        icon: CalendarIcon,
      },
      {
        path: '/info',
        title: 'Info',
        icon: InformationCircleIcon,
      },
    ],
    []
  )

  return (
    <Box
      background="chakra-body-bg"
      position="fixed"
      shadow="2xl"
      width="100%"
      borderTop="1px"
      borderColor="chakra-border-color"
      paddingY={2}
      bottom={0}
      justifyContent="center"
      display="flex"
      alignItems="center"
    >
      <Box
        w={{ base: '85%', md: '45%', lg: '40%' }}
        justifyContent="space-around"
        display="flex"
        alignItems="center"
      >
        {navigation.map((item, idx) => {
          let condition = item.path === location.pathname
          return (
            <Link key={idx} to={item.path}>
              <Box
                justifyContent="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
                color={condition ? 'twitter.500' : 'gray.500'}
              >
                <div>
                  <item.icon width={24} />
                </div>
                <Text fontSize="xs">{item.title}</Text>
              </Box>
            </Link>
          )
        })}
      </Box>
    </Box>
  )
}

export default BottomTabNavigator
