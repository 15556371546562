import { useToken } from 'hooks/useDirectus'
import { Navigate, Outlet } from 'react-router-dom'
import ThemedSuspense from './ThemedSuspense'

const AuthRoute = () => {
  const { data, isLoading } = useToken()

  if (isLoading) return <ThemedSuspense />

  return data ? <Navigate to="/" /> : <Outlet />
}

export default AuthRoute
