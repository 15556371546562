import { BoxProps } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { useAppSelector } from 'redux/hooks'
import { dashboardBottomTabShow } from 'redux/reducers/dashboard/dashboard.selector'
import BottomTabNavigator from './BottomTabNavigator'
import Layout from './Layout'

const Container = ({
  children,
  ...props
}: BoxProps & { children: ReactNode }) => {
  const show = useAppSelector(dashboardBottomTabShow)
  return (
    <>
      <Layout {...props} pt={4} pb={20}>
        {children}
      </Layout>
      {show && <BottomTabNavigator />}
    </>
  )
}

export default Container