import { useToken } from 'hooks/useDirectus'
import { Navigate, Outlet } from 'react-router-dom'
import ThemedSuspense from './ThemedSuspense'

const PrivateRoute = () => {
  const { data, isLoading } = useToken()

  if (isLoading) return <ThemedSuspense />

  return data ? <Outlet /> : <Navigate to="login" />
}

export default PrivateRoute
