import { Stack, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

const WrapForm = ({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) => {
  return (
    <Stack spacing={1}>
      <Text fontWeight="medium">{title}</Text>
      {children}
    </Stack>
  )
}

export default WrapForm
